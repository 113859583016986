<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('certificate.certificate-relation-list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:header>
          <CertificateRelationFilter 
          v-on:onFilterChange="onFilterChange"
          ></CertificateRelationFilter>
        </template>
        <template v-slot:item.customs_type="{ item }">
          {{ get_customs_type_name(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="disableItem(item,$t('commodity.commodity'),item.name)"
              >mdi-block-helper</v-icon>
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import BaseTable from "./BaseTable.vue";
import CertificateRelationFilter from "../filters/CertificateRelationFilter"
export default {
  data: (vm) => ({
    url: "/commodity/certificate-relation/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("certificate.number"),
        value: "certificate.certificate_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.accreditation-number"),
        value: "accreditation_number",
      },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("actions"),
      //   value: "actions",
      //   width: "5%",
      // },
    ],
    commodity_items: [],
    certificate_items: [],
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    CertificateRelationFilter,
    CommonAlertDialog
  },
  methods: {
    createItem(item) {
      this.getFormDatas();
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = this.action_items.create;
      this.showDialog = true;
    },
    getFormDatas() {
      this.getApi("/commodity/commodity/", this.setCommodityItems);
      this.getApi("/commodity/certificate/", this.setCertificateItems);
    },
    setCommodityItems(datas) {
      this.commodity_items = [];
      datas.results.forEach((data) => {
        this.commodity_items.push({
          text:
            data["name"] +
            (data["commodity_number"]
              ? "(" + data["commodity_number"] + ")"
              : ""),
          value: data["id"],
        });
      });
    },
    setCertificateItems(data) {
      this.setListItems(data.results, this.certificate_items, "certificate_number");
    },
    get_customs_type_name(item){
      return item.customs_type?this.getHanText(item.customs_type,"name"):""
    },
  },
  mounted() {
    this.getParams['enable'] = true
  },
};
</script>